import { useStore } from '@nanostores/react'
import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'
import { useState, useMemo, type FC, useRef } from 'react'

import Link from '@/components/Link/Link'
import useClickAway from '@/hooks/use-click-away-listener'
import { useIsClient } from '@/hooks/useIsClient'
import { $isLoggedIn } from '@/stores/account/computed'
import { $customer } from '@/stores/account/stores'
import type { CMSAccountMenu } from '@/types/sanity'
import { getLoginHref } from '@/utilities/auth'

import './account.scss'

interface AccountProps {
  account: CMSAccountMenu
}

const Account: FC<AccountProps> = ({ account }) => {
  const loggedIn = useStore($isLoggedIn)
  const customer = useStore($customer)

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const isClient = useIsClient()

  const ref = useRef(null)

  useClickAway(ref, () => setIsPopoverOpen(false))

  const items = useMemo(() => {
    return (loggedIn && account.loggedInItems) || []
  }, [loggedIn])

  const loginHref = isClient
    ? getLoginHref(window.location.href)
    : getLoginHref()

  return (
    <Popover.Root open={isPopoverOpen}>
      <Popover.Trigger asChild>
        {(isClient && loggedIn && (
          <button
            className={clsx([
              'account-header-button',
              'type-label',
              { 'account-header-button--active': isPopoverOpen },
            ])}
            aria-label="go to the cart page"
            onClick={() => {
              setIsPopoverOpen((o) => !o)
            }}
          >
            {account.loggedInTitle}
          </button>
        )) ||
          (account.loggedOutTitle && (
            <a
              className={clsx(['account-header-button', 'type-label'])}
              href={loginHref}
            >
              {account.loggedOutTitle}
            </a>
          ))}
      </Popover.Trigger>
      <Popover.Portal>
        {items.length && (
          <Popover.Content ref={ref} className="account__popover" align="start">
            {isClient && (
              <p className="type-h5 account-header__user">
                Welcome,&nbsp;
                {customer?.firstName}&nbsp;{customer?.lastName?.substring(0, 1)}
                .
              </p>
            )}
            <ul className="account-header__list">
              {items.map((item) => (
                <li
                  key={item.link.url}
                  className="account-header__list-item type-label"
                >
                  <Link
                    classes={['account-header__list-item__link', 'type-label']}
                    link={item.link}
                  >
                    {item.link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </Popover.Content>
        )}
      </Popover.Portal>
    </Popover.Root>
  )
}

export default Account
